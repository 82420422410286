
import Vue from 'vue'
import RPHFooterNav from 'rph/molecules/RPHFooterNav/index.vue'
import RPHFooterSocials from 'rph/molecules/RPHFooterSocials/index.vue'
import RPHPayments from 'rph/molecules/RPHPayments/index.vue'

import RocketIcon from '~/assets/images/company-logo.svg?inline'

export default Vue.extend({
  name: 'RPHFooter',
  components: {
    RPHFooterNav,
    RPHFooterSocials,
    RPHPayments,
    RocketIcon,
  },
  props: {},
  data() {
    return {}
  },
  head() {
    return {
      script: [
        {
          json: this.structuredData,
          type: 'application/ld+json',
        },
      ],
    }
  },
  async fetch() {
    if (!this.$store.getters['rph-footer/settings'].length) {
      await this.$store.dispatch('rph-footer/getSettings')
    }
  },
  fetchOnServer: false,
  computed: {
    copyright() {
      return this.$store.getters['rph-footer/copyright']
    },
    structuredData() {
      return {
        '@context': 'https://schema.org',
        '@type': 'Pharmacy',
        address: {
          '@type': 'PostalAddress',
          addressLocality: 'Алматы',
          addressCountry: 'Казахстан',
        },
        openingHours: 'пн-вс 10.00-19.00',
        image: `https://aurma.kz/rph-logo.svg`,
      }
    },
    workHours() {
      return this.$store.getters['rph-footer/workHours']
    },
  },
  methods: {},
})
